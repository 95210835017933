<template>
  <div class="ma-2">
    <v-row dense>
      <v-col cols="3" v-for="i in 4" :key="i" align-self="center">
        <v-card outlined min-height="100" class="d-flex align-center">
          <v-layout row wrap align-center class="ma-0">
            <v-flex align-self-center lg3 class="text-center">
              <v-icon> mdi-anchor </v-icon>
            </v-flex>
            <v-flex align-self-center lg9>
              <div>
                <span class="d-block font-weight-medium grey--text text--darken-3 body-2"> Visitors in Campus </span>
                <span class="d-block font-weight-black"> 25 </span>
              </div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
    </v-row>
    <div class="mt-2">
      <data-iterator :headers="payloadHeaders" :payload="payload" :key="reInit"></data-iterator>
    </div>
    <v-dialog v-model="drawer" width="600">
      <v-card elevation="0">
        <v-card-title class="primary darken-2 white--text px-4">
          <span class="body-1 font-weight-medium"> {{ modelObj._id ? 'Update Gatepass' : 'Add Gatepass'}} </span>
        </v-card-title>
        <v-card-text class="pa-2">
          <v-form ref="form">
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <v-radio-group v-model="modelObj.gatepass" hide-details row mandatory class="ma-0 mb-1">
                    <v-radio label="Visitor" value="visitor"></v-radio>
                    <v-radio label="Hostel" value="hostel"></v-radio>
                    <v-radio label="College" value="college"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense hide-details label="Name" v-model="modelObj.name" :rules="setValidation('requiredValidation')"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense hide-details label="Email" v-model="modelObj.email"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense hide-details label="Phone Number" v-model="modelObj.phone"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense hide-details label="Id Card type" v-model="modelObj.proof_type"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense hide-details label="Id Card number" v-model="modelObj.proof_number"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense hide-details label="Address" v-model="modelObj.address"></v-text-field>
                </v-col>
                <!-- <v-col cols="6">
                  <v-radio-group v-model="modelObj.status" hide-details row>
                    <v-radio label="Vip" value="vip"></v-radio>
                    <v-radio label="Blacklisted" value="blacklisted"></v-radio>
                  </v-radio-group>
                </v-col> -->
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-layout class="d-flex justify-center">
            <v-btn color="success" class="mx-2" @click="saveUpdateHandler()"> {{ (modelObj._id) ? 'Update' : 'Save' }} </v-btn>
            <v-btn @click="drawer= false" color="error"> Cancel </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {
    'data-iterator': () => import('@/components/DataIterator.vue')
  },
  data () {
    return {
      drawer: false,
      routesDrawer: false,
      reInit: 0,
      listOfForms: [],
      listOfUsers: [],
      modelObj: {},
      payload: {
        // showBackBtn: false,
        create: () => this.drawer = true,
        items: [],
        showSelect: false,
        actionsList: [
          {
            click: (item) => this.editHandler(item._id),
            icon: 'mdi-pencil',
            color: 'primary'
          },
          {
            click: (item) => this.deleteHandler(item._id),
            icon: 'mdi-delete',
            color: 'error'
          }
        ]
      }
    }
  },
  mounted () {
    this.getAllUsers()
    this.getListHandler()
  },
  computed: {
    payloadHeaders () {
      return [{
        text: 'Name',
        value: 'name'
      }, {
        text: 'Email',
        value: 'email'
      }, {
        text: 'Phone number',
        value: 'phone'
      }, {
        text: 'Actions',
        value: 'actions'
      }]
    }
  },
  methods: {
    getAllUsers () {
      this.$_execute('get', 'users').then(({ data }) => {
        this.listOfUsers = data
      })
    },
    getListHandler () {
      this.$_execute('get', 'gatepass').then(response => {
        this.payload.items = response.data
        this.reInit++
      })
    },
    saveUpdateHandler () {
      let url = this.modelObj._id ? `gatepass/${this.modelObj._id}` : `/gatepass`
      let method = this.modelObj._id ? `put` : `post`
      this.$_execute(method, url, this.modelObj).then(() => {
        this.$root.$emit('snackbar', { message: this.modelObj._id ? 'Updated Successfully' : 'Saved Successfully', color: 'success'})
        this.$refs.form.reset()
        this.modelObj = {}
        this.drawer = false
        this.getListHandler()
      })
    },
    editHandler (id) {
      this.$_execute('get', `gatepass/${id}`).then(response => {
        this.drawer = true
        let model = response.data[0]
        this.modelObj = model
      })
    },
    deleteHandler (id) {
      this.$root.$emit('delete', {
        module: 'gatepass',
        _id: id,
        onResolve: () => {
          this.dialog = false
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getListHandler()
        }
      })
    }
  }
}
</script>
